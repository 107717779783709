import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ThemeService } from "../../../services/app/theme.service";

// Icons
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navBurger') navBurger: ElementRef;
  @ViewChild('navMenu') navMenu: ElementRef;
  // Icons
  arrowIcn = faChevronDown;
  settingsIcn = faCog;
  reloadIcon = faSync;
  logoutIcn = faPowerOff;
  bugReport = faBug;
  srchIcon = faSearch;

  theme: string | 'light' | undefined;

  // Header settings
  settings: boolean;

  constructor(private setTheme: ThemeService) { }

  toggleNavbar() {
    console.log('click');
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }


  ngOnInit(): void {
    let themeSet = this.setTheme.getTheme();
    if ( themeSet === null || undefined ) {
      this.theme = 'light';
    } else {
      this.theme = themeSet;
    }
  }

  // Header interactions
// Switching application theme
  switchTheme() {
    this.settings = false;
    if ( this.theme === 'light' ) {
      this.theme = 'dark';
      this.setTheme.setTheme('dark');
    } else {
      this.theme = 'light';
      this.setTheme.setTheme('light');
    }
  }
}
