import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() {
  }

  setTheme(theme: string) {
    localStorage.setItem('theme', theme);
  }
  getTheme() {
    return localStorage.getItem('theme');
  }
}
