<header class="header {{theme}}">
    <nav class="navbar is-transparent" role="navigation" aria-label="main navigation">

        <div class="navbar-brand">
          <a class="navbar-item" href="/">

                <h1>{{'Admissions Knowledgebase' | titlecase}}</h1>
            </a>
          <a (click)="toggleNavbar()" role="button" #navBurger class="navbar-burger" aria-label="menu" aria-expanded="true" data-target="navMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

      <div id="navMenu" class="navbar-menu" #navMenu>
        <div class="navbar-start">
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/campuses">Schools</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/locator">Locator</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/sop">SOP's</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/faq">FAQ's</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/value">Value</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/tip">Tip of the Week</a>
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/tech">Tech How To</a>
        </div>
        <div class="navbar-end">
          <a class="navbar-item" (click)="toggleNavbar()" routerLink="/search"><fa-icon [icon]="srchIcon"></fa-icon></a>
        </div>

      </div>


    </nav>




    <div class="settingsSection" *ngIf="settings">
        <div class="setting">
            <p class="mr-4">Light mode</p>
            <input class="tgl tgl-color" id="cb1" type="checkbox" *ngIf="theme !== 'light' " (click)="switchTheme()"/>
            <input class="tgl tgl-color" id="cb1" type="checkbox" checked *ngIf="theme === 'light' " (click)="switchTheme()"/>
            <label class="tgl-btn" for="cb1"></label>
        </div>
    </div>
</header>
